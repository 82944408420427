import React from "react"
import { Link } from "gatsby"
// import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
// import 'prismjs/themes/prism-okaidia.css'; ダウンロードし、Localに移動（"./src/styles/prism-okaidia.css"） 

export default ({ children }) => {
/*
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
*/
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">Ushiji's Blog</Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <Link to="/">Ushiji's Blog</Link> | Copyright © 2020 Ushiji All Rights Reserved.
      </footer>
    </div>
  )
}